import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefExoticComponent,
} from "react";
import Header from "./components/Header";
import ChatWindow from "./components/ChatWindow";
import ChatInput from "./components/ChatInput";
import "./components/index.scss";
import { postRequest } from "../../../../utils/httpRequest";

const AiChat: ForwardRefExoticComponent<{
  showAiChat: boolean;
  handleShowAiChat: (e, val) => void;
}> = forwardRef(({ showAiChat, handleShowAiChat }, ref) => {
  const startingMessage = {
    role: "assistant",
    content: "Why hello there! How can I help?",
    id: undefined,
  };
  const [messages, setMessages] = useState<
    {
      role: string;
      content: string;
      id?: string;
    }[]
  >([startingMessage]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(showAiChat);
  const [maxMessagesReached, setMaxMessagesReached] = useState(false);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (messages.length >= 15) {
      setMaxMessagesReached(true);
    } else {
      setMaxMessagesReached(false);
    }
  }, [messages]);

  const messagesEndRef = useRef(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (event) => {
    console.log(
      `[${new Date().toLocaleString()}] Sending message from client via websocket`,
    );

    event.preventDefault();
    if (!userInput.trim()) return;

    // Append the new user message
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userInput },
    ]);

    setLoading(true);
    setUserInput("");
    postRequest("/api/ai/courses/deep_thought.json", {
      // Use the updated messages array
      messages: [
        ...messages,
        { role: "user", content: userInput }, // Include the current user message in the request
      ],
    })
      .then((response) => {
        setLoading(false);

        // @ts-ignore
        const { data } = response;
        // Append the response message to the current messages state
        setMessages((prevMessages) => [...prevMessages, data]);
      })
      .catch((error) => {
        console.error(error);

        setLoading(false);
      });
  };

  const resetConversation = () => {
    setMessages([startingMessage]);
    setUserInput("");
  };

  const toggleOpen = (e) => {
    handleShowAiChat(e, !open);
  };

  useImperativeHandle(ref, () => ({
    toggleOpen,
  }));

  return (
    <div className="ai-lesson-chat">
      {showAiChat ? (
        <div className="h-100dvh bg-gray-100 bg-opacity-50 backdrop-filter backdrop-blur-md flex flex-col backdrop-filter">
          <Header handleShowAiChat={handleShowAiChat} />
          <main className="flex flex-1 flex-col w-full overflow-y-auto h-screen relative pt-12">
            {error && (
              <div className="bg-red-500 text-white text-center py-2 mb-4">
                {error}
              </div>
            )}

            <ChatWindow
              messages={messages}
              isUserMessageWaiting={loading}
              maxMessagesReached={maxMessagesReached}
            />
            <div ref={messagesEndRef} />
          </main>

          <div className="sticky inset-x-0 bottom-0 w-full z-10">
            <ChatInput
              userInput={userInput}
              loading={loading}
              handleSubmit={handleSubmit}
              setUserInput={setUserInput}
              resetConversation={resetConversation}
              maxMessagesReached={maxMessagesReached}
            />
          </div>
        </div>
      ) : (
        <button
          onClick={(e) => handleShowAiChat(e, true)}
          className="ui button ai-lesson-chat__button"
        >
          ✨AI
        </button>
      )}
    </div>
  );
});

export default AiChat;
