import React from "react";
import { Link } from "react-router-dom";
import CurrentUser from "../CurrentUser";
import Announcement from "../../Announcement";
import { CurrentUserResource } from "../../../stores/CurrentUserStore/Types";

interface Props {
  handleShowSignInSideBar: () => void;
  handleShowLoggedInSideBar: () => void;
  onClickHeaderLogo: () => void;
  getCurrentUser: () => void;
  currentUser: CurrentUserResource;
  handleShowNotificationModal: (val: boolean) => void;
  showNotificationModal: boolean;
  layout: string;
  userXp: number;
}

const DesktopNav: React.FC<Props> = ({
  handleShowSignInSideBar,
  handleShowLoggedInSideBar,
  onClickHeaderLogo,
  currentUser,
  layout,
  userXp,
  getCurrentUser,
  handleShowNotificationModal,
  showNotificationModal,
}) => {
  const isLessonLayout =
    layout === "lesson" || layout === "lesson-hide-signin-button";
  const hideSignInButton = layout === "lesson-hide-signin-button";
  return (
    <div className="ui grid">
      <div className="desktop only row">
        <div className="column">
          <div className="ui secondary menu fixed new-navbar-desktop new-navbar">
            <Announcement />
            <div className="ui secondary menu">
              {!isLessonLayout && (
                <>
                  <a
                    href={
                      currentUser.kind === "current-user" ? "/profile" : "/"
                    }
                    className="item"
                  >
                    <div className="logo">
                      <img
                        className="dark"
                        src={require("../../../../../assets/images/facelift/logos/dark_rectangle.png")}
                        alt=""
                      />
                    </div>
                  </a>

                  <Link to="/team_plans" className="item">
                    For teams
                  </Link>
                  <Link to="/courses" className="item">
                    Courses
                  </Link>
                  <Link to="/about_us" className="item">
                    About
                  </Link>

                  <a
                    href="https://www.42courses.com/blog"
                    target="_blank"
                    className="item"
                  >
                    Blog
                  </a>
                  <Link to="/faqs" className="item">
                    FAQ
                  </Link>
                  <Link to="/community" className="item">
                    Community
                  </Link>
                </>
              )}
              {isLessonLayout && (
                <>
                  <div className="left menu lesson-nav" />
                  <div className="center menu lesson-nav">
                    <a
                      className="header item header-logo branded"
                      href="/profile"
                    >
                      <div className="logoIcon">
                        <img
                          className="logo-mark"
                          src={require("../../../../../assets/images/facelift/logos/dark_blob.png")}
                        />
                      </div>

                      {currentUser.kind === "current-user" &&
                        currentUser.current_course_brand && (
                          <>
                            <div className="logo-divider"></div>
                            <div className="logo-brand">
                              <img src={currentUser.current_course_brand} />
                            </div>
                          </>
                        )}
                    </a>
                  </div>
                </>
              )}
              {currentUser.kind === "current-user-absent" ? (
                <div className="right menu">
                  <div className="signInButton item">
                    <a
                      className={`ui button ${
                        hideSignInButton ? "util-visibility-hidden" : ""
                      }`}
                      onClick={() => handleShowSignInSideBar()}
                    >
                      Sign In
                    </a>
                  </div>
                </div>
              ) : (
                <CurrentUser
                  currentUser={currentUser}
                  userXp={userXp}
                  getCurrentUser={getCurrentUser}
                  showNotificationModal={showNotificationModal}
                  handleShowLoggedInSideBar={handleShowLoggedInSideBar}
                  handleShowNotificationModal={handleShowNotificationModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNav;
